.container {
  min-height: 100vh;
  padding-bottom: 7.5rem;
}

.general-container {
  display: flex;
  flex-direction: row;
  gap: 3.5rem;
  width: 50%;
  margin-top: 2rem;
}

.general-container label {
  font-weight: 700;
}

.notifications-appointments-container {
  display: flex;
  flex-direction: row;
  gap: 4.5rem;
  width: 60%;
  margin-top: 1.5rem;
}

.notifications-container {
  display: flex;
  flex-direction: row;
  max-width: 17.5vw;
  /* gap: 6rem; */
  justify-content: space-between;
  width: 50%;
  margin-top: 1.5rem;
}

.emails-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 30vw;
  margin-top: 1.5rem;
}

.settings-category-header {
  color: grey;
}
