.modal-container {
  margin-top: 35px !important;
}

.full-height {
  min-height: calc(100vh - 250px) !important;
  max-height: calc(100vh - 250px) !important;
}

.details-container {
  position: relative;
  display: flex;
  flex-direction: row;
}

.client-container {
  flex: 1;
}

.client-photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
}

.service-container {
  flex: 1;
  margin-left: 30px;
}

.custom-divider {
  position: absolute;
  left: 50%;
  top: 0px;
  bottom: 0px;
  border-left: 1px solid rgba(34, 36, 38, 0.15);
}

.header-details {
  margin-top: 20px;
}

/* .details-subcontainer { */
/* display: flex; */
/* flex-direction: row; */
/* align-items: center; */
/* } */

.details-info {
  margin-top: 5px;
  margin-left: 10px;
  font-size: 16px;
}

.details-info > p {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.6);
}

.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.month-dropdown {
  margin-bottom: 10px;
  width: 150px;
}

.timeslots-container {
  /* display: flex; */
  /* flex-direction: column; */
  justify-content: center;
  min-height: 280px;
}

.date {
  text-align: center;
}

/* Semantic */
.ui.dropdown .menu .selected.item img {
  width: 28px;
  height: 28px;
}

.ui.tiny.image {
  width: 80px;
  height: 80px;
}

.center {
  display: flex !important;
  flex: 1;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
}

.confirmation-details {
  margin: 5px 30px 0px;
}

.client-name {
  font-size: 20px;
}

.ui.modal > .header {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}
