.horizontal-menu {
  flex-direction: row;
  align-items: flex-start !important;
}

.menu-wrapper--inner {
  display: flex;
  justify-content: flex-start;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.menu-item {
  background: transparent;
  padding: 5px 15px;
  flex-basis: 0;
  align-items: flex-start;
  color: black;
  text-align: center;
}

.menu-item-wrapper:focus {
  outline: 0px !important;
}

.list-item {
  background-color: #ecf6fe;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 10px !important;
}

.list-item.active {
  background-color: white;
  /* outline: 2px solid #fff3a3; */
  outline: 2px solid #ff7bb0;
  font-weight: bold;
}

.date {
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

.date > p {
  margin: 0px;
}

.scroll-menu-arrow {
  margin: 25px 10px 0px 10px;
  cursor: pointer;
}
