.container {
  min-height: 100vh;
}

.place-container {
  margin-bottom: 6rem;
}

.view-type-container {
  display: flex;
  flex-direction: row;
  gap: 10rem;
  margin-top: 2rem;
}

.currency-container {
  display: flex;
  flex-direction: row;
  gap: 14rem;
  margin-top: 2rem;
}

.general-container {
  display: flex;
  flex-direction: row;
  gap: 3.5rem;
  width: 50%;
  margin-top: 2rem;
}

.settings-row {
  display: flex;
  flex-direction: row;
  gap: 14rem;
  margin-top: 2.5rem;
}
