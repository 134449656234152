.custom-input {
  color: white !important;
}
.custom-input > input {
  background-color: rgba(255, 255, 255, 0.3) !important;
  color: white !important;
  width: 300px;
  margin-right: 200px !important;
}
.custom-input > input:focus {
  background-color: rgb(127, 127, 221);
  color: white !important;
}
