/* Waiting on https://github.com/intljusticemission/react-big-calendar/pull/1510 */

.rbc-timeslot-group {
  min-height: 70px;
}

.rbc-events-container {
  margin-right: 0px !important;
}

.rbc-event-label {
  font-weight: bold;
  line-height: 14px;
}

.rbc-event-content {
  font-size: 12px;
}
