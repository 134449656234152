.bottom-save-bar {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100vw;
  opacity: 0;
  height: 0;
  overflow: hidden;
  left: calc(-50vw + 50%);
  background-color: rgb(197, 197, 197);
}

.bottom-save-bar-active {
  opacity: 1;
  height: 4rem;
  position: fixed;
  bottom: 0;
  gap: 2rem;
  display: flex;
  flex-direction: row;
  width: 100vw;
  transition: opacity 200ms ease-out;
  overflow: hidden;
  left: calc(-50vw + 50%);
  z-index: 3;
  background-color: rgb(197, 197, 197);
}

.bottom-save-items {
  margin-top: 1.4rem;
  color: #393939;
}
